var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AmcText',_vm._g(_vm._b({staticClass:"row",class:{
      'align-items-start': _vm.alignStart,
      'align-items-end': _vm.alignEnd,
      'align-items-stretch': _vm.alignStretch,
      'align-items-center': _vm.alignCenter,
      'align-items-baseline': _vm.alignBaseline,
      'justify-content-start': _vm.justifyStart,
      'justify-content-end': _vm.justifyEnd,
      'justify-content-center': _vm.justifyCenter,
      'justify-content-around': _vm.justifyAround,
      'justify-content-between': _vm.justifyBetween,
      'justify-content-evenly': _vm.justifyEvenly,
   }},'AmcText',_vm.attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }